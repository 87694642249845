import { Toaster } from 'react-hot-toast';
import IconCheck from '@/images/icons/icon_check.svg?react';
import IconError from '@/images/icons/icon_error.svg?react';

export const EqutumToast = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        success: { className: 'border border-positive-tint ', icon: null },
        error: { className: 'border border-negative-tint ', icon: null },
      }}
    />
  );
};

export const PositiveToast = ({ message }: { message: string }) => {
  return (
    <div className="flex items-center gap-2.5">
      <div className="my-auto flex h-6 min-h-[24px] w-6 items-center justify-center gap-2.5 self-stretch rounded-[100px] bg-positive">
        <IconCheck />
      </div>
      <div className="my-auto flex-1 shrink basis-0 self-stretch whitespace-pre-wrap text-sm font-medium leading-snug tracking-wide text-positive">
        {message}
      </div>
    </div>
  );
};

export const NegativeToast = ({ message }: { message: string }) => {
  return (
    <div className="flex items-center gap-2.5">
      <div className="my-auto flex h-6 min-h-[24px] w-6 items-center justify-center gap-2.5 self-stretch rounded-[100px] bg-negative text-negative">
        <IconError />
      </div>
      <div className="my-auto flex-1 shrink basis-0 self-stretch text-sm font-medium leading-snug tracking-wide text-negative">
        {message}
      </div>
    </div>
  );
};
